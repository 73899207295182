import React, { ReactElement } from "react"
import DefaultLayout from "../layouts/default"
import data from "../data/tnc"
import Heading1 from "../components/heading1"
import TNCContainer from "../containers/tnc"

interface Props { }

function TNCPage({ }: Props): ReactElement {
  return (
    <div>
      <DefaultLayout title="Terms and Conditions">
        <TNCContainer />
      </DefaultLayout>
    </div>
  )
}

export default TNCPage
